import { bindable, inject }                     from 'aurelia-framework';
import { AppContainer }                         from 'resources/services/app-container';
import { BaseFormViewModel }                    from 'base-form-view-model';
import { ConfirmRequestPaidDialog }             from 'resources/elements/html-elements/dialogs/confirm-request-paid-dialog';
import { DialogService }                        from 'aurelia-dialog';
import { FormSchema }                           from 'modules/periodic-inspections/awaiting-payment/payments/pay/form-schema';
import { PeriodicInspectionPaymentsRepository } from 'modules/periodic-inspections/awaiting-payment/payments/services/repository';

@inject(AppContainer, DialogService, FormSchema, PeriodicInspectionPaymentsRepository)
export class ConfirmPeriodicInspectionPayment extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.confirm-payment';
    @bindable formId      = 'periodic-inspections.awaiting-payment.payments.confirmation-form';

    alert         = {};
    model         = {};
    schema        = {};
    buttonsSchema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogService, formSchema, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.formSchema    = formSchema;
        this.repository    = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'periodic-inspections.awaiting-payment.manage',
            'periodic-inspections.awaiting-payment.edit',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/periodic-inspections/awaiting-payment/payments/form.html');
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema        = this.formSchema.schema(this);
            this.buttonsSchema = this.formSchema.buttonsSchema(this);
        });
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

    /**
     * Submits the form
     *
     * @returns {*}
     */
    submit() {
        this.dialogService
            .open({ viewModel: ConfirmRequestPaidDialog })
            .whenClosed(dialogResponse => {
                if (!dialogResponse.wasCancelled) {
                    this.alert = null;

                    return this.repository
                        .pay(this.model.id, this.model)
                        .then(response => this.afterSubmitCallback(response))
                        .then(response => response.status === true ? this.appContainer.router.navigateToRoute('periodic-inspections.awaiting-payment.index', { tab: 'payments' }) : false);
                }
            });
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Redirects the user to the given lift's view form
     *
     * @param id
     */
    navigateToLift(id) {
        this.appContainer.router.navigateToRoute('lifts.lifts.view', { id: id });
    }

}
