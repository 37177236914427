import { bindable, inject }                                  from 'aurelia-framework';
import { AppContainer }                                      from 'resources/services/app-container';
import { BaseFormViewModel }                                 from 'base-form-view-model';
import { DialogController }                                  from 'aurelia-dialog';
import { FormSchema }                                        from 'modules/periodic-inspections/awaiting-payment/payments/communications/create/form-schema';
import { PeriodicInspectionPaymentCommunicationsRepository } from 'modules/periodic-inspections/awaiting-payment/payments/communications/services/repository';

@inject(AppContainer, DialogController, FormSchema, PeriodicInspectionPaymentCommunicationsRepository)
export class CreatePeriodicInspectionPaymentCommunication extends BaseFormViewModel {

    formId = 'periodic-inspections.awaiting-payment.payments.communications.creation-form';

    @bindable alert;
    @bindable settings = {
        title: 'form.title.communicate-non-payment',
        size:  'modal-md',
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogController, formSchema, repository) {
        super(appContainer);

        this.dialogController = dialogController;
        this.formSchema       = formSchema;
        this.repository       = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'periodic-inspections.awaiting-payment.manage',
            'periodic-inspections.awaiting-payment.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        this.reference = params;

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData().then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.repository
            .create(this.reference.payment_id, this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => response.status)
            .catch(console.log.bind(console));
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

}
