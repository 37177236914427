import { bindable, inject }                                from 'aurelia-framework';
import { AppContainer }                                    from 'resources/services/app-container';
import { AssignPeriodicInspection }                        from 'modules/periodic-inspections/awaiting-assignment/edit/index';
import { BaseListViewModel }                               from 'base-list-view-model';
import { DialogService }                                   from 'aurelia-dialog';
import { Downloader }                                      from 'resources/services/downloader';
import { FilterFormSchema }                                from 'modules/periodic-inspections/awaiting-assignment/filter-form-schema';
import { InfoDialog }                                      from 'resources/elements/html-elements/dialogs/info-dialog';
import { PeriodicInspectionsAwaitingAssignmentRepository } from 'modules/periodic-inspections/awaiting-assignment/services/repository';
import moment                                              from 'moment';

@inject(AppContainer, DialogService, Downloader, FilterFormSchema, PeriodicInspectionsAwaitingAssignmentRepository)
export class ListPeriodicInspectionsAwaitingAssignment extends BaseListViewModel {

    listingId = 'periodic-inspections-awaiting-assignment-listing';

    @bindable headerTitle = 'listing.periodic-inspections.awaiting-assignment';
    @bindable repository;
    @bindable datatable;
    @bindable legend = [
        { color: 'success', name: 'messages.on-time' },
        { color: 'warning', name: 'messages.less-than-one-month-remaining' },
        { color: 'danger', name: 'messages.overdue' },
    ];

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param downloader
     * @param filterFormSchema
     * @param repository
     */
    constructor(appContainer, dialogService, downloader, filterFormSchema, repository) {
        super(appContainer);

        this.dialogService    = dialogService;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.repository       = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'periodic-inspections.awaiting-assignment.manage',
            'periodic-inspections.awaiting-assignment.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.periodic-inspections.periodic-inspection',
            repository:      this.repository,
            show:            null,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [],
            options:         [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('inspecoes_periodicas'),
                },
            ],
            buttons:         [
                {
                    label:   'form.button.assign-selected',
                    icon:    'icon-user-plus',
                    color:   'teal',
                    visible: this.appContainer.authenticatedUser.can(['periodic-inspections.awaiting-assignment.manage', 'periodic-inspections.awaiting-assignment.edit'])
                        && this.appContainer.authenticatedUser.belongsToManagingEntity(),
                    action:  () => this.assignSelectedRecords(),
                },
            ],
            selectable:      this.appContainer.authenticatedUser.can(['periodic-inspections.awaiting-assignment.manage', 'periodic-inspections.awaiting-assignment.edit'])
                && this.appContainer.authenticatedUser.belongsToManagingEntity(),
            sorting:         {
                column:    5,
                direction: 'asc',
            },
            columns:         [
                {
                    data:   'code',
                    name:   'lifts.code',
                    title:  'form.field.lift-number',
                    type:   'link',
                    action: (row) => this.appContainer.router.navigateToRoute('lifts.lifts.view', { id: row.lift_id }),
                },
                {
                    data:  'address',
                    name:  'lifts.address',
                    title: 'form.field.address',
                },
                {
                    data:  'parish',
                    name:  'parishes.name',
                    title: 'form.field.parish',
                },
                {
                    data:  'maintenance_company',
                    name:  'maintenance_company_data.name',
                    title: 'form.field.emie',
                },
                {
                    data:  'owner',
                    name:  'owner_data.name',
                    title: 'form.field.owner',
                },
                {
                    data:            'certificate_deadline',
                    name:            'lift_certificates.deadline',
                    title:           'form.field.certificate-deadline',
                    type:            'label',
                    color:           (row) => this.processDeadlineColor(row.certificate_deadline),
                    valueConverters: [
                        { name: 'dateFormat' },
                    ],
                },
            ],
        };
    }

    /**
     * Returns the correct stoplight based on the current date and deadline
     *
     * @param date
     *
     * @return {string}
     */
    processDeadlineColor(date) {
        let deadline        = moment(date);
        let closeToDeadline = moment(date).subtract(1, 'month');
        let now             = moment();

        if (now.isAfter(deadline, 'day')) {
            return 'badge-danger';
        } else if (now.isAfter(closeToDeadline, 'day')) {
            return 'badge-warning';
        }

        return 'badge-success';
    }

    /**
     * Handles the `assign selected` action button
     *
     * @returns {*}
     */
    assignSelectedRecords() {
        let selectedRows = this.datatable.instance.selectedRows;

        if ( ! selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        this.dialogService
            .open({ viewModel: AssignPeriodicInspection, model: { procedures: selectedRows } })
            .whenClosed(response => {
                if ( ! response.wasCancelled) {
                    this.datatable.instance.reload();
                    this.datatable.instance.selectedRows.splice(0, this.datatable.instance.selectedRows.length);
                    this.datatable.instance.allRowsChecked = false;
                }
            });
    }

}
