import { inject }                                                from 'aurelia-framework';
import { Communication }                                         from 'modules/administration/models/communication';
import { CommunicationType }                                     from 'modules/management/models/communication-type';
import { CommunicationTypesRepository }                          from 'modules/management/communication-types/services/repository';
import { PeriodicInspectionPaymentCommunicationFilesRepository } from 'modules/periodic-inspections/awaiting-payment/payments/communications/services/files-repository';

@inject(CommunicationTypesRepository, PeriodicInspectionPaymentCommunicationFilesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        type_id: CommunicationType.NON_PAYMENT,
    };

    /**
     * Constructor
     *
     * @param communicationTypesRepository
     * @param filesRepository
     */
    constructor(communicationTypesRepository, filesRepository) {
        this.communicationTypesRepository = communicationTypesRepository;
        this.filesRepository              = filesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Communication}
     */
    model() {
        let model = new Communication();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[*]}
     */
    schema(viewModel) {
        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.communication-type',
            size:         12,
            required:     false,
            options:      [],
            remoteSource: this.communicationTypesRepository.active.bind(this.communicationTypesRepository),
            attributes:   {
                disabled: true,
            },
        };

        this.observations = {
            type:     'textarea',
            key:      'observations',
            label:    'form.field.observations',
            size:     12,
            required: false,
        };

        this.file_id = {
            type:     'file-dropzone',
            key:      'file_id',
            label:    'form.field.file',
            size:     12,
            required: false,
            url:      this.filesRepository.uploadUri(),
        };

        return [
            [this.type_id],
            [this.observations],
            [this.file_id],
        ];
    }

}
