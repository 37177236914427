import { bindable, bindingMode, inject }                from 'aurelia-framework';
import { AppContainer }                                 from 'resources/services/app-container';
import { BaseListViewModel }                            from 'base-list-view-model';
import { ConfirmPaymentCancellationDialog }             from 'resources/elements/html-elements/dialogs/confirm-payment-cancellation-dialog';
import { CreatePeriodicInspectionPaymentCommunication } from 'modules/periodic-inspections/awaiting-payment/payments/communications/create/index';
import { DialogService }                                from 'aurelia-dialog';
import { Downloader }                                   from 'resources/services/downloader';
import { PaymentStatus }                                from 'modules/administration/models/payment-status';
import { PeriodicInspectionPaymentsRepository }         from 'modules/periodic-inspections/awaiting-payment/payments/services/repository';
import moment                                           from 'moment';

@inject(AppContainer, DialogService, Downloader, PeriodicInspectionPaymentsRepository)
export class ListPeriodicInspectionPayments extends BaseListViewModel {

    listingId = 'periodic-inspections-payments-listing';

    @bindable filterModel;
    @bindable repository;
    @bindable datatable;
    @bindable masterPage;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterFormIsDirty;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param downloader
     * @param repository
     */
    constructor(appContainer, dialogService, downloader, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.downloader    = downloader;
        this.repository    = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'periodic-inspections.awaiting-payment.manage',
            'periodic-inspections.awaiting-payment.view',
        ]);
    }

    /**
     * Handles bind event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.periodic-inspections.periodic-inspection',
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('periodic-inspections.awaiting-payment.payments.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['periodic-inspections.awaiting-payment.manage', 'periodic-inspections.awaiting-payment.view']),
            },
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [
                {
                    icon:    'icon-cross2',
                    tooltip: 'form.button.cancel',
                    action:  (row) => this.cancelPayment(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['periodic-inspections.awaiting-payment.manage', 'periodic-inspections.awaiting-payment.delete'])
                        && row.status.id === PaymentStatus.AWAITING_PAYMENT,
                },
                {
                    icon:    'icon-user-check',
                    tooltip: 'form.button.confirm-payment',
                    action:  (row) => this.appContainer.router.navigateToRoute('periodic-inspections.awaiting-payment.payments.pay', { id: row.id }),
                    visible: (row) => this.appContainer.authenticatedUser.can(['periodic-inspections.awaiting-payment.manage', 'periodic-inspections.awaiting-payment.edit'])
                        && row.status.id === PaymentStatus.AWAITING_PAYMENT
                        && this.appContainer.authenticatedUser.belongsToManagingEntity(),
                },
                {
                    icon:    'icon-user-cancel',
                    tooltip: 'form.button.communicate-non-payment',
                    action:  (row) => this.createCommunication(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['periodic-inspections.awaiting-payment.manage', 'periodic-inspections.awaiting-payment.create'])
                        && row.status.id === PaymentStatus.AWAITING_PAYMENT
                        && row.has_communications === false
                        && this.appContainer.authenticatedUser.belongsToMaintenanceCompany(),
                },
                {
                    icon:    'icon-file-text3',
                    tooltip: 'form.button.invoice',
                    action:  (row) => this.downloadInvoice(row),
                    visible: (row) => row.invoice_id !== null,
                },
            ],
            options:         [],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    5,
                direction: 'asc',
            },
            columns:         [
                {
                    data:    'customer',
                    name:    'entity_data.name',
                    title:   'form.field.customer',
                    display: this.appContainer.authenticatedUser.belongsToManagingEntity(),
                },
                {
                    data:  'type',
                    name:  'payment_types.name',
                    title: 'form.field.type',
                },
                {
                    data:            'value',
                    name:            'value',
                    title:           'form.field.amount',
                    searchable:      false,
                    valueConverters: [
                        { name: 'currency' },
                    ],
                },
                {
                    data:  'entity',
                    name:  'payment_references.entity',
                    title: 'form.field.entity',
                },
                {
                    data:            'reference',
                    name:            'payment_references.reference',
                    title:           'form.field.reference',
                    valueConverters: [
                        { name: 'paymentReference' },
                    ],
                },
                {
                    data:            'deadline',
                    name:            'payments.deadline',
                    title:           'form.field.payment-deadline',
                    type:            'label',
                    color:           (row) => this.processDeadlineColor(row),
                    valueConverters: [
                        { name: 'dateFormat' },
                    ],
                },
                {
                    data:  'status',
                    name:  'payment_statuses.name',
                    title: 'form.field.status',
                    type:  'label',
                },
                {
                    data:       'alerts',
                    title:      '',
                    type:       'alert-icons',
                    orderable:  false,
                    searchable: false,
                    items:      [
                        {
                            color:   () => 'text-default',
                            icon:    () => 'icon-clipboard3',
                            visible: () => true,
                            popover: {
                                visible:         () => true,
                                trigger:         'hover',
                                placement:       'left',
                                title:           'form.field.lift(s)',
                                contentCallback: (row) => row.lifts.join('<br />'),
                                html:            true,
                            },
                        },
                        {
                            color:   () => 'text-default',
                            icon:    () => 'icon-user-cancel',
                            visible: (row) => row.has_communications === true,
                            popover: {
                                visible:         () => true,
                                trigger:         'hover',
                                placement:       'right',
                                contentCallback: () => this.appContainer.i18n.tr('messages.has-non-payment-communication'),
                            },
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Returns the correct stoplight based on the current date and deadline
     *
     * @param row
     *
     * @return {string}
     */
    processDeadlineColor(row) {
        if (row.status.id === PaymentStatus.AWAITING_PAYMENT) {
            let deadline        = moment(row.deadline);
            let closeToDeadline = moment(row.deadline).subtract(2, 'weeks');
            let now             = moment();

            if (now.isAfter(deadline, 'day')) {
                return 'badge-danger';
            } else if (now.isAfter(closeToDeadline, 'day')) {
                return 'badge-warning';
            }

            return 'badge-success';
        }

        return row.status.id === PaymentStatus.PAID ? 'badge-success' : 'badge-secondary';
    }

    /**
     * Handles the `cancel` action button
     *
     * @param row
     *
     * @returns {*}
     */
    cancelPayment(row) {
        this.dialogService
            .open({
                viewModel: ConfirmPaymentCancellationDialog,
                model: {
                    resource: this.datatable.resource,
                    records:  row.lifts.join('<br />'),
                    action:   {
                        method:     this.repository.cancel.bind(this.repository),
                        parameters: [row.payment_id],
                    },
                },
            })
            .whenClosed(response => {
                if ( ! response.wasCancelled) {
                    this.masterPage.reloadDatatables();
                }
            });
    }

    /**
     * Handles the `communication non-payment` action button
     *
     * @param row
     *
     * @returns {*}
     */
    createCommunication(row) {
        this.dialogService
            .open({ viewModel: CreatePeriodicInspectionPaymentCommunication, model: row })
            .whenClosed((response) => {
                if ( ! response.wasCancelled) {
                    this.masterPage.reloadDatatables();
                }
            });
    }

    /**
     * Downloads the given payment's invoice
     *
     * @param row
     */
    downloadInvoice(row) {
        this.repository.invoice(row.id).then(response => this.downloader.download(response, 'fatura', 'pdf'));
    }

}
